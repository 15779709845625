<template>
  <button
    :class="['appearance-none', computedClasses, btnClass]"
    v-on="$listeners"
    :type="type"
    :disabled="disabled"
  >
    <template v-if="icon">
      <slot name="icon">
        <component :is="icon" v-bind="iconOption" />
      </slot>
    </template>
    <template v-if="loading">
      <slot name="loader">
        <div class="flex justify-center">
          <loader />
        </div>
      </slot>
    </template>
    <template v-else>
      <span v-if="text" v-html="text"></span>
    </template>
  </button>
</template>


<script>
import Loader from './Loader.vue';
export default {
  components: {
    Loader
  },
  props: {
    type: {
      type: String,
      default: "button"
    },
    color: {
      type: String,
      default: ""
    },

    outline: {
      type: Boolean,
      default: false
    },

    rounded: {
      type: Boolean,
      default: true
    },

    full: {
      type: Boolean,
      default: false
    },

    btnClass: {
      type: String,
      default: ""
    },

    btnHeight: {
      type: String,
      default: "h-10"
    },

    text: {
      type: String,
      default: ""
    },

    icon: {
      type: String,
      default: ""
    },

    disabled: {
      type: Boolean,
      default: false
    },

    applyDisabledColor: {
      type: Boolean,
      default: false
    },

    loading: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    computedClasses(){
      return [
        "outline-none focus:outline-none",
        this.btnColors,
        this.rounded && "rounded",
        this.full && "w-full",
        this.btnHeight,
        this.loading && "loading",
        this.disabled && this.applyDisabledColor && "disabled"
      ];
    },

    btnColors() {
      let color = "";
      switch (this.color) {
        case "primary":
          color = this.outline
            ? "bg-transparent border-2 border-kblue-300 text-kblue-300 hover:border-kblue-200 hover:text-kblue-200"
            : "text-white bg-kblue-300 hover:bg-kblue-300";
          break;
        case "primary-dark":
          color = this.outline
            ? "bg-transparent border border-bgk-4 text-bgk-4 hover:border-bgk-4 hover:text-bgk-4"
            : "text-white bg-bgk-4 hover:bg-bgk-4";
          break;

        case "secondary":
          color = this.outline
            ? "bg-transparent border text-kbutton-1 border-kbutton-1 h-search sm:h-minput  hover:border-kbutton-2 hover:text-kbutton-2 active:border active:border-kbutton-3 py-1 sm:py-2 px-4 sm:px-6 text-sm sm:text-base focus:outline-none"
            : "text-white border h-search sm:h-minput bg-kbutton-1 hover:bg-kbutton-2 active:border active:border-kbutton-3 px-4 sm:px-6 text-sm sm:text-base focus:outline-none";
          break;
        default:
          color = this.color
      }
      return color;
    },
  }
}
</script>

<style lang="postcss" scoped>
.loading {
  @apply pointer-events-none text-transparent;
}
.disabled {
  @apply pointer-events-none bg-compteur-500 text-tex-11 border-compteur-500 cursor-not-allowed;
}
</style>